import { z } from 'zod';
import { cx } from '@ui/utils';
import { IconChevronRight } from '@ui/icons';
import { getNavigationTreeSchema } from '@api/common/browse';
import NavLink from './../NavLink';
import styles from './MenuItem.module.css';

type MenuItemProps = {
  item: z.infer<typeof getNavigationTreeSchema.shape.navigationTree.element>;
  depth?: number;
  highlightColor?: string;
  basePath: string;
  parentPath?: string | null;
};

const MenuItem = ({
  item,
  depth = 0,
  highlightColor,
  basePath,
  parentPath = null,
}: MenuItemProps) => {
  const hasChildren = item?.children?.length > 0 && depth < 2;

  const currentPath = parentPath
    ? `${parentPath}/${item?.name}`.toLowerCase()
    : item?.name?.toLowerCase() ?? null;

  const renderMenuItem = (menuChildren: null | JSX.Element = null) => {
    return (
      <div
        key={item.name}
        className={cx(styles.menuContainer, 'py-2', {
          'px-4 text-black/80 transition-colors hover:bg-andisor-blue-50 hover:text-andisor-blue':
            depth > 0,
        })}
      >
        <NavLink
          className="flex items-center justify-between"
          href={`${basePath}/${currentPath}`}
          highlightActiveLink={depth === 0}
          highlightColor={highlightColor}
        >
          <span className={cx(depth === 0 && 'whitespace-nowrap')}>{item.name}</span>
          {depth > 0 && hasChildren && <IconChevronRight width={15} stroke={1.8} />}
        </NavLink>
        {menuChildren}
      </div>
    );
  };

  if (hasChildren) {
    return renderMenuItem(
      <div className={styles.menuContent}>
        <div
          className={cx(
            'min-w-[230px] cursor-pointer rounded-[6px] bg-white py-1 text-sm text-andisor-navy shadow-lg',
            {
              'mt-2': depth === 0,
              'ml-2': depth === 1,
            }
          )}
        >
          {item?.children.map(childItem => (
            <MenuItem
              key={childItem.name}
              item={childItem}
              depth={depth + 1}
              highlightColor={highlightColor}
              basePath={basePath}
              parentPath={currentPath}
            />
          ))}
        </div>
      </div>
    );
  }

  return renderMenuItem();
};

export default MenuItem;
