import NavLink from '../NavLink';

type LinkMenuItemProps = {
  name: string;
  path: string;
  highlightColor?: string;
  highlightActiveLink?: boolean;
};

const LinkMenuItem = ({
  name,
  path,
  highlightColor,
  highlightActiveLink = false,
}: LinkMenuItemProps) => {
  return (
    <NavLink
      className="flex items-center"
      href={path}
      highlightColor={highlightColor}
      highlightActiveLink={highlightActiveLink}
    >
      <span className="whitespace-nowrap">{name}</span>
    </NavLink>
  );
};

export default LinkMenuItem;
