import Image from 'next/image';
import { z } from 'zod';
import { getNavigationTreeSchema } from '@api/common/browse';
import { ROUTES } from '@constants/route';
import LinkMenuItem from './LinkMenuItem';
import MenuItem from './MenuItem';

type HoverMenuProps = {
  navData: z.infer<typeof getNavigationTreeSchema.shape.navigationTree>;
  isRetailerUser?: boolean;
  isTBNGUser?: boolean;
  isCityBeachUser: boolean;
  isKidStuffUser: boolean;
  isCombatSportsUser: boolean;
  isNewBalanceUser: boolean;
  basePath: string;
};

const HoverMenu = ({
  navData,
  isRetailerUser = true,
  isTBNGUser,
  isCityBeachUser,
  isKidStuffUser,
  isCombatSportsUser,
  isNewBalanceUser,
  basePath,
}: HoverMenuProps) => {
  const highlightColor =
    isCityBeachUser || isKidStuffUser || isCombatSportsUser ? 'text-andisor-blue' : undefined;

  return (
    <div className="flex gap-10 text-sm tablet:hidden">
      {navData?.map(item => (
        <MenuItem key={item.name} item={item} highlightColor={highlightColor} basePath={basePath} />
      ))}
      {!isTBNGUser && !isCombatSportsUser && !isNewBalanceUser && isRetailerUser && (
        <LinkMenuItem name="Brands" path={ROUTES.COMMON.BRANDS} highlightColor={highlightColor} />
      )}
      {isNewBalanceUser && isRetailerUser && (
        <LinkMenuItem
          name="Store"
          path={ROUTES.SELLERS.NEW_BALANCE_BRAND_PAGE}
          highlightColor={highlightColor}
        />
      )}
      {isTBNGUser && (
        <div className="flex items-center gap-2">
          <LinkMenuItem
            name="First Nations"
            path={ROUTES.COMMON.FIRST_NATION_BRANDS}
            highlightColor={highlightColor}
          />
          <Image
            src="/assets/images/first-nations-logo.png"
            alt="First Nation"
            width={20}
            height={20}
            className="mt-[-0.5px]"
          />
        </div>
      )}
    </div>
  );
};

export default HoverMenu;
