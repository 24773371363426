export const NEW_BALANCE_NAV_ITEMS = ['Men', 'Women', 'Kids & Baby'];
export const NEW_BALANCE_BRAND_ID_DEMO = '89';
export const NEW_BALANCE_BRAND_ID_DEV = '105';

export const getNewBalanceNavTree = () => {
  return NB_NAV_LINKS;
};

const NB_NAV_LINKS = [
  {
    name: 'Women',
    targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
    productCategoryPaths: [],
    userCategoryPaths: [],
    children: [
      {
        name: 'Shoes',
        targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
        productCategoryPaths: ['/product-category/shoes'],
        userCategoryPaths: [],
        children: [
          {
            name: 'Sneakers',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            productCategoryPaths: ['/product-category/shoes/sneakers'],
            userCategoryPaths: [],
            children: [],
          },
          {
            name: 'Performance',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            productCategoryPaths: ['/product-category/shoes/athletic-shoes'],
            userCategoryPaths: [],
            children: [],
          },
        ],
      },
      {
        name: 'Clothing',
        targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
        productCategoryPaths: ['/product-category/clothing'],
        userCategoryPaths: [],
        children: [
          {
            name: 'Pants',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            productCategoryPaths: ['/product-category/clothing/pants'],
            userCategoryPaths: [],
            children: [],
          },
          {
            name: 'Activewear',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            productCategoryPaths: ['/product-category/clothing/activewear'],
            userCategoryPaths: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    name: 'Men',
    targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
    productCategoryPaths: [],
    userCategoryPaths: [],
    children: [
      {
        name: 'Shoes',
        targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
        productCategoryPaths: ['/product-category/shoes'],
        userCategoryPaths: [],
        children: [
          {
            name: 'Sneakers',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            productCategoryPaths: ['/product-category/shoes/sneakers'],
            userCategoryPaths: [],
            children: [],
          },
          {
            name: 'Performance',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            productCategoryPaths: ['/product-category/shoes/athletic-shoes'],
            userCategoryPaths: [],
            children: [],
          },
        ],
      },
      {
        name: 'Clothing',
        targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
        productCategoryPaths: ['/product-category/clothing'],
        userCategoryPaths: [],
        children: [
          {
            name: 'T-Shirts & Singlets',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            productCategoryPaths: ['/product-category/clothing/tops/t-shirts'],
            userCategoryPaths: [],
            children: [],
          },
          {
            name: 'Sweats & Hoodies',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            productCategoryPaths: ['/product-category/clothing/sweatshirts-and-hoodies'],
            userCategoryPaths: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    name: 'Kids & Baby',
    targetAudiencePaths: ['/target-audience/kids'],
    productCategoryPaths: [],
    userCategoryPaths: [],
    children: [
      {
        name: 'Boyswear (3-16 years)',
        targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
        productCategoryPaths: ['/product-category/clothing', '/product-category/shoes'],
        userCategoryPaths: [],
        children: [
          {
            name: 'Boys Shoes',
            targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
            productCategoryPaths: ['/product-category/shoes'],
            userCategoryPaths: [],
            children: [],
          },
        ],
      },
      {
        name: 'Girlswear (3-16 years)',
        targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
        productCategoryPaths: ['/product-category/clothing', '/product-category/shoes'],
        userCategoryPaths: [],
        children: [
          {
            name: 'Shoes',
            targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
            productCategoryPaths: ['/product-category/shoes'],
            userCategoryPaths: [],
            children: [],
          },
        ],
      },
    ],
  },
];
