export const DEMO_PARENT_IDS = {
  // (retailer -> 'Myer Company', 'test@myer.com')
  MYER_USERS: [759],
  // (retailer -> 'Frontline Stores', 'frontline@andisor.com')
  FRONTLINE_USERS: [782],
  // (retailer -> 'INTERSPORT Australia', 'intersport@andisor.com'), (brand -> 'New Balance Australia Pty Ltd', 'newbalance@andisor.com')
  NEW_BALANCE_USERS: [783, 86],
  // (retailer -> 'City Beach Australia', 'citybeach@andisor.com'), (brand -> 'KMD Brands', 'ripcurl@andisor.com')
  CITYBEACH_USERS: [731, 88],
  // (retailer -> 'Kidstuff', 'kidstuff@andisor.com')
  KIDSTUFF_USERS: [784],
  // (retailer -> 'fn@andisor.com')
  TBNG_USERS: [760],
  TBNG_USERS_STAGING: [939],
};

// any new dynamic landing page user config should be added here only if such page has been created first on Crystallize
export const LANDING_PAGE_DEMO_PATH_CONFIG_MAP: { [key: string]: number[] } = {
  myer: DEMO_PARENT_IDS.MYER_USERS,
  newbalance: DEMO_PARENT_IDS.NEW_BALANCE_USERS,
  citybeach: DEMO_PARENT_IDS.CITYBEACH_USERS,
  kidstuff: DEMO_PARENT_IDS.KIDSTUFF_USERS,
};
